$c-text: #000000;
$c-text-gray: #b4b4b4;
$c-white: #ffffff;

$c-border: #ececec;
$c-border-gray: #dbdee0;

// Colors
$c-1: #10069f;
$c-2: #b9975b;

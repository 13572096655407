.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-align {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-space {
    justify-content: space-between;
}

.flex-reverse {
    flex-direction: row-reverse;
}

.flex-end {
    align-self: flex-end;
}

.flex-col {
    flex-direction: column;
}

.label {
    font-weight: 800;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: $base * 0.5;
        height: 100%;

        background-color: $c-2;
        border-radius: 2px;
    }
}
.label-min {
    top: -$base * 2;
    left: $base;
    &::after {
        top: 0;
        left: -$base;
    }
}
.label-max {
    right: $base;
    bottom: -$base * 2;
    &::after {
        bottom: 0;
        right: -$base;
    }
}

.relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.z-index {
    z-index: 5;
}

.br {
    border-radius: $base * 2.5;
}

.shadow {
    box-shadow: $shadow;
}

.full {
    width: 100%;
}

.bb {
    border-bottom: 2px solid $c-border-gray;
}

.overflow {
    overflow: hidden;
}

.display-none {
    display: none;
}

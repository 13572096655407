.card {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: $base * 20;
    user-select: none;
    @include mq(laptop) {
        height: $base * 25;
    }
}

.card-height {
    height: $base * 20;
    @include mq(laptop) {
        height: $base * 25;
    }
}

.card-question {
    background-color: $c-white;
    border-radius: 2px;
    padding: $base;
    height: 100%;
    color: $c-1;
}
.card-completed {
    height: $base * 27;
}
// .bg-card {
//     border-radius: $base * 0.5;
//     background-color: $c-2;
//     box-shadow: 0 4px 0 0 darken($c-2, 5%);
// }

// .card-top-left {
//     position: absolute;
//     top: $base * 2;
//     left: $base * 2;
// }

// .card-top-right {
//     position: absolute;
//     top: $base * 2;
//     right: $base * 2;
// }

// .card-answer {
//     background-color: darken($c-white, 4%);
//     box-shadow: 0 4px 0 0 darken($c-white, 8%);
// }

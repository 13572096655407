.h-screen {
    height: 100vh;
}

.h-full {
    height: 100%;
}

.min-h-screen {
    height: 100vh;
}

.min-h-16 {
    min-height: 4rem;
}

.h-0 {
    height: 0;
}

.h-1 {
    height: 0.25rem;
}

.h-2 {
    height: 0.5rem;
}

.h-3 {
    height: 0.75rem;
}

.h-4 {
    height: 1rem;
}

.h-5 {
    height: 1.25rem;
}

.h-6 {
    height: 1.5rem;
}

.h-7 {
    height: 1.75rem;
}

.h-10 {
    height: 2.25rem;
}

.h-14 {
    height: 3.6rem;
}

.h-16 {
    height: 4rem;
}

.h-20 {
    height: 5rem;
}

.h-28 {
    height: 7rem;
}

.h-48 {
    height: 12rem;
}

.h-56 {
    height: 14rem;
}

.h-64 {
    height: 22rem;
}

.auto {
    margin: 0 auto;
}

h1,
h2,
h3 {
    font-weight: 800;
}

.center {
    text-align: center;
}

.fw-b {
    font-weight: 800;
}

// .title {
//     font-size: $base * 3.4;
// }

// .title-card {
//     font-size: $base * 2;
//     line-height: $base * 2.4;
// }

p {
    line-height: $base * 2.1;
}

.fs-xs {
    font-size: $base * 1;
}

.fs-s {
    font-size: 24px;
}

.fs-m {
    font-size: 18px;
    line-height: 22px;
    @include mq(laptop) {
        font-size: 24px;
        line-height: 28px;
    }
}

.fs-l {
    font-size: 22px;
    line-height: 26px;
    @include mq(laptop) {
        font-size: 34px;
        line-height: 38px;
    }
}

.fs-xl {
    font-size: $base * 3;
}

.a-l {
    text-align: left;
}

.a-r {
    text-align: right;
}

.italic {
    font-style: italic;
}
.text-wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.leading-tight {
    line-height: 1.25;
}
.leading-snug {
    line-height: 1.375;
}
.leading-normal {
    line-height: 1.5;
}

.competition {
    background: none;
    width: 100%;
    display: flex;
    position: relative;
    border-color: transparent;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-radius: $radius;
    align-items: center;
    justify-content: center;
    margin-bottom: $base * 2;
    background-color: $c-white;
    padding: $base * 2.5 $base * 2;
    height: $base * 3.6;
    &:first-child {
        margin-bottom: $base * 6;
        &::after {
            color: $c-white !important;
            content: "OF...";
            position: absolute;
            top: calc(100% + 1.6rem);
            color: $c-white;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.competition-active {
    color: $c-2;
}

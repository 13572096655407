// Top
.mt {
    margin-top: $base;
}

.mt-m {
    margin-top: $base * 2;
}

.mt-s {
    margin-top: $base * 0.5;
}

.mt-l {
    margin-top: $base * 5;
}

// Bottom
.mb {
    margin-bottom: $base;
}

.mb-m {
    margin-bottom: $base * 2;
}

.mb-s {
    margin-bottom: $base * 0.5;
}

.mb-l {
    margin-bottom: $base * 5;
}

// Left
.ml {
    margin-left: $base;
}

.ml-s {
    margin-left: $base * 0.5;
}

// Right
.mr {
    margin-right: $base;
}

.mr-s {
    margin-right: $base * 0.5;
}

.mt-2 {
    margin-top: 1rem;
}

.mb-2 {
    margin-bottom: 1rem;
}

.mt-4 {
    margin-top: 2rem;
}

.mt-6 {
    margin-top: 3rem;
}

.mb-4 {
    margin-bottom: 2rem;
}

.ml-2 {
    margin-left: 1rem;
}

$base: 0.8rem;
$base__gutter: $base * 2;

$radius: 2px;

$mq--s: 480px;
$mq--ss: 580px;
$mq--sm: 768px;
$mq--m: 1024px;
$mq--l: 1280px;
$mq--xl: 1440px;
$mq--xxl: 1920px;

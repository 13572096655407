.select {
    width: 100%;
    color: $c-text;
    background-color: $c-white;
    border: 3px solid $c-2;
    overflow: hidden;
}

.select-trigger {
    height: 3.1rem;
    padding: 0 $base;
}

.option {
    height: $base * 3.6;
    width: 100%;
    padding: 0 $base;
    font-weight: 800;
    transition: color 500ms $ease-slow;
    &:hover {
        color: $c_2;
    }
}

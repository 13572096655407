.c-w {
    color: $c-white;
}

.c-text {
    color: $c-text;
}

.c-gray {
    color: $c-text-gray;
}

.c-gold {
    color: $c-2;
}
